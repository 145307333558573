import { Box } from "@mui/material";
import ViewForm from "src/components/common/ViewForm";
import { getStyles } from "src/styles/theme";
import defautlStyles from "./styles";
import ViewImages from "src/components/common/ViewImage";

export function NurseryView({ data, images, submitted }: any) {
  const styles = getStyles(defautlStyles);

  const nurseryDetail = data;
  const viewData = {
    "Is Nursery Created": nurseryDetail.isNursingCreated ? "Yes" : "No",
    "Area (Ha.)": nurseryDetail.area,
    "Number of Beds (No.)": nurseryDetail.numberOfBeds,
    "Month/Year of Creation": nurseryDetail.monthOfCreation,
    "No of SeedLings Available": nurseryDetail.noOfSeedLingsAvailable,
    Onsite: nurseryDetail.onsite ? "Yes" : "No",
    Offsite: nurseryDetail.offsite ? "Yes" : "No",
    "Distance from the Site": nurseryDetail.distanceFromTheSite,
    Observations: nurseryDetail.remark,
  };

  return (
    <Box>
      <Box {...styles("viewWrapper")}>
        {Object.entries(viewData).map((item) => (
          <ViewForm label={item[0]} value={item[1]} />
        ))}

        <Box {...styles("heading")}>Geo Tagged Images</Box>
        <Box {...styles("imageContainer")}>
          {images?.map((item) => (
            <ViewImages fileUrl={item.url} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
