import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Card from "src/components/common/Card";
import MapComponent from "src/components/common/KML";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import {
  Button,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import Snackbar from "src/components/common/Snackbar";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { path } from "src/constants/path";
import { getStyles } from "src/styles/theme";
import { SnackbarType } from "src/types/common";
import {
  getDemandNotePdf,
  getLandDetailsById,
  getProjectById,
  queryHistory,
  verifyDemandDraft,
} from "src/utils/api/dashboard";
import { dateAndTimeStamp, formatToIndianCurrency } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "../styles";
import { useAppSelector } from "src/redux/store";

const DemandNoteView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const id = location?.state?.id || "";

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [showSnackbar, setShowSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
  });
  const [donwloadEnable, setDownloadEnable] = useState(false);
  const { mutate } = useMutation(
    "changeStatus",
    ({
      id,
      data,
    }: {
      id: string;
      data: {
        isVerified: boolean;
        remark: string;
        docId?: string;
      };
    }) =>
      verifyDemandDraft({
        id,
        data,
      }),
    {
      onSuccess: () => {
        navigate(path.home);
      },
      onError: () => {},
    }
  );

  const { data: landData, refetch: projectFetch } = useQuery(
    ["landDetails", id],
    () => getLandDetailsById(id),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res.data);
      },
      onError: (req: AxiosError) => {},
      refetchOnMount: true,
      enabled: !!id,
    }
  );

  const projectID = landData?.data?.projectDetailId;
  const { data: projectDetails, isLoading: isProjectDetailLoading } = useQuery(
    ["projects", projectID],
    () => getProjectById(projectID),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
      enabled: !!projectID,
    }
  );

  const { mutate: mutateLandOwnershipQueryData } = useMutation(
    "query",
    ({
      landOwnerShipId,
      query,
      type,
      underQuery,
    }: {
      landOwnerShipId: string;
      query: string;
      type: string;
      underQuery: string;
    }) =>
      queryHistory({
        landOwnerShipId,
        query,
        type,
        underQuery,
      }),
    {
      onSuccess: () => {
        setValue("query", "");
        projectFetch();
      },
      onError: () => {},
    }
  );

  const {
    control,
    watch,
    setValue,
    trigger,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      demandNote: null,
    },
  });

  const docId = getValues("demandNoteDoc");
  const user = useAppSelector((state) => state.root.user);

  const baseUrl = process.env.REACT_APP_ADMIN_USER_BASE_URL;

  const { isLoading } = useQuery(
    ["downloadDemandNote", landData?.data?.id],
    () => getDemandNotePdf(landData?.data?.id),
    {
      enabled: donwloadEnable,
      onSuccess: (response) => {
        const pdfUrl = `${baseUrl}${response.data.downloadPath}`;
        window.open(pdfUrl, "_blank");
        setDownloadEnable(false);
      },
      onError: (error) => {
        console.error("Error occurred while downloading demand note:", error);
        setShowSnackbar({
          severity: "error",
          message: "Something went wrong while downloading the PDF.",
        });
        setDownloadEnable(false);
      },
    }
  );
  const handleDownloadDemandNote = () => {
    setDownloadEnable(true);
  };

  return (
    <Box component="form">
      <DashboardWrapper
        title="Verify Cost Estimate"
        customStyles={{
          container: { position: "relative" },
          children: { paddingBottom: "80px" },
        }}
        backPath={`/`}
        showBackBtn
      >
        <Box {...styles("projectName")}>
          Registration No: {landData?.data?.id}
        </Box>

        <Box {...styles("formWrapper")}>
          <Box {...styles("heading")}>Eco-Restoration Block Details</Box>
          <ViewForm
            label="Land Registration No"
            value={projectDetails?.data?.registrationNo}
          />
          <ViewForm label="Land Type" value={projectDetails?.data?.landType} />
          <ViewForm
            label="Total Land Area(Hectares)"
            value={projectDetails?.data?.landSpecification?.totalArea}
          />
          <ViewForm
            label="State"
            value={projectDetails?.data?.address?.stateName}
          />
          <ViewForm
            label="District"
            value={projectDetails?.data?.address?.districtName}
          />
          <ViewForm
            label="Division"
            value={projectDetails?.data?.landSpecification?.division}
          />
          <ViewForm
            label="Range"
            value={projectDetails?.data?.landSpecification?.range}
          />
          <ViewForm
            label="Beat"
            value={projectDetails?.data?.landSpecification?.beat}
          />
          <ViewForm
            label="TreeCanopy Density"
            value={(+projectDetails?.data?.landSpecification
              ?.treeCanopyDensity).toFixed(2)}
          />
          <ViewForm
            label="Edaphic Details"
            value={projectDetails?.data?.landSpecification?.edaphicDetails}
          />
          <ViewForm
            label="Forest Type"
            value={projectDetails?.data?.landSpecification?.forestType}
          />
          <ViewForm
            label="Geological Features"
            value={projectDetails?.data?.landSpecification?.geologicalFeatures}
          />
          <ViewForm
            label="Hydrological Conditions"
            value={
              projectDetails?.data?.landSpecification?.hydrologicalConditions
            }
          />
          <ViewForm
            label="Slope And Terrain"
            value={projectDetails?.data?.landSpecification?.slopeAndTerrain}
          />
          <ViewForm
            label="Additional Information"
            value={projectDetails?.data?.landSpecification?.description}
          />
        </Box>

        <Box {...styles("formWrapper")}>
          <Box {...styles("heading")}>Cost Estimates Details</Box>

          <ViewForm label="Username" value={landData?.data?.user?.firstName} />
          <ViewForm label="Email" value={landData?.data?.user?.email} />
          <ViewForm
            label="Phone Number"
            value={`${landData?.data?.user?.phoneNumber}`}
          />
          <ViewForm
            label="Land Area Requested"
            value={(+landData?.data?.totalArea).toFixed(2)}
          />
          <ViewForm
            label="Net Planned Land Area (Hectares)"
            value={landData?.data?.netPlantationArea}
          />
          <ViewForm
            label="Eco-Restoration Amount"
            value={`${formatToIndianCurrency(landData?.data?.demandNote)}`}
          />
          <ViewForm
            label="Admin Charges"
            value={`${formatToIndianCurrency(landData?.data?.icfreCharges)}`}
          />
          <ViewForm
            label="Demand Note Amount"
            value={`${
              (landData?.data?.demandNote &&
                landData?.data?.icfreCharges &&
                formatToIndianCurrency(
                  +landData?.data?.demandNote + +landData?.data?.icfreCharges
                )) ??
              "-"
            }`}
          />
          <ViewForm
            label="Tree Canopy"
            value={(+landData?.data?.treeCanopy).toFixed(2)}
          />

          <ViewForm
            label="Tentative Start Month For Plantation"
            value={landData?.data?.tentativeDateForPlantation}
          />

          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>KML File</Box>
            <Box {...styles("documentGroup")}>
              <ViewImages
                fileUrl={landData?.data?.grantedLandImage}
                label={""}
              />
            </Box>
            <MapComponent kmlUrl={landData?.data?.grantedLandImage} />
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Ms Excel copy of the cost estimate</Box>
            <Box {...styles("documentGroup")}>
              <ViewImages
                fileUrl={landData?.data?.paymentBreakdownImage}
                label={""}
              />
            </Box>
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>
              PDF of technically sanctioned/approved cost estimate
            </Box>
            <Box {...styles("documentGroup")}>
              <ViewImages
                fileUrl={landData?.data?.paymentBreakdownPdfImage}
                label={""}
              />
            </Box>
          </Box>
          <Box {...styles("imageWrapper")}>
            {landData?.data?.geoTaggedImages.map((value) => {
              return (
                <Card key={value} title={`GeoTagged Image`} image={value} />
              );
            })}
          </Box>
        </Box>
        {landData?.data?.remark && (
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}> Pervious Remarks</Box>
            <ViewForm label="Pervious Remarks" value={landData?.data?.remark} />
          </Box>
        )}
        {landData?.data?.landOwnerShipQuery.length > 0 && (
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Administrator Query</Box>
            {landData?.data?.landOwnerShipQuery.map((item) => (
              <Box sx={{ paddingTop: "20px" }}>
                <ViewForm
                  label={item.user.firstName}
                  value={item.query}
                  date={dateAndTimeStamp(item.created_at, "IST")}
                />
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ width: "60%" }}>
          <Box>
            <Button
              text="Download Demand Note"
              onClick={handleDownloadDemandNote}
            />
            {isLoading && <CircularProgress />}
          </Box>
          <FileUpload
            name="demandNoteDoc"
            label="Upload Demand Note"
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            maxLimit={1}
            multiple={true}
            customStyles={{
              label: {
                whiteSpace: "wrap",
              },
            }}
            supportedFormatMessage="Upload Demand Note"
            trigger={trigger}
            required={true}
            rules={{
              validate: {
                isLength: (value) => {
                  return value && value["length"] >= 1;
                },
              },
            }}
            dragDropHeading=""
          />
        </Box>
        {user.data.role !== "Approver-view-only" && (
          <Box sx={{ marginTop: "30px" }}>
            <Input
              name="query"
              label="Query"
              control={control}
              errors={errors}
              multiline
              height="360px"
              customStyles={{
                input: {
                  height: "auto",
                  fontFamily: "Poppins",
                  width: { sm: "100%", lg: "60%" },
                },
              }}
            />
          </Box>
        )}
        <Box sx={{ marginTop: "30px" }}>
          <Input
            name="remarks"
            label="Remarks"
            control={control}
            errors={errors}
            multiline
            height="360px"
            customStyles={{
              input: {
                height: "auto",
                fontFamily: "Poppins",
                width: { sm: "100%", lg: "60%" },
              },
            }}
          />
        </Box>
        {user.data.role !== "Approver-view-only" && (
          <Box {...styles("btnWrapper")}>
            <Button
              text="Reject"
              variant="outlined"
              color="error"
              onClick={() => {
                mutate({
                  id,
                  data: {
                    isVerified: false,
                    remark: watch("remarks"),
                  },
                });
                navigate(path.home);
              }}
              customStyles={{
                root: {
                  fontSize: "18px",
                  color: "error.main",
                  borderColor: "error.main",
                },
              }}
            />
            <Button
              text="Issue Demand Note"
              type="submit"
              onClick={() => {
                mutate({
                  id,
                  data: {
                    isVerified: true,
                    remark: watch("remarks"),
                    docId: docId[0]?.key,
                  },
                });
                navigate(path.home);
              }}
              disabled={!isValid}
              customStyles={{
                root: {
                  fontSize: "16px",
                  textWrap: "noWrap",
                },
              }}
            />
          </Box>
        )}
        {user.data.role !== "Approver-view-only" && (
          <Button
            text="Raise Query"
            onClick={() => {
              mutateLandOwnershipQueryData({
                landOwnerShipId: id,
                query: watch("query"),
                type: "LANDOWNERSHIP",
                underQuery: "UNDER_QUERY",
              });
            }}
            disabled={!watch("query")}
            customStyles={{
              root: {
                fontSize: "16px",
                position: "absolute",
                bottom: "20px",
              },
            }}
          />
        )}
      </DashboardWrapper>
      <Snackbar
        open={!!showSnackbar.message}
        message={showSnackbar.message}
        severity={showSnackbar.severity}
        resetSnackbar={() => setShowSnackbar({ ...showSnackbar, message: "" })}
      />
    </Box>
  );
};

export default DemandNoteView;
