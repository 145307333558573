import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents/Buttons";
import HorizontalTabs from "src/components/common/HorizontalTabs";
import { fundPaymentTabs, fundStatus } from "src/constants/common";
import { getFundRequest } from "src/utils/api/dashboard";
import { dateAndTimeStamp, downloadSheet, formatINR } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "./styles";
import { path } from "src/constants/path";
import SearchBar from "src/components/common/SearchField";
import { useAppSelector } from "src/redux/store";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import { getStyles } from "src/styles/theme";

const FundApplication = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("currentTab");

  const [tableRows, setTableRows] = useState([]);

  const [activeTab, setActiveTab] = useState(tab ?? undefined);

  const [states, setStates] = useState<any>([]);

  const user = useAppSelector((state) => state.root.user);

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [queryParams, setQueryParams] = useState<{
    status: string;
    offset: number;
    limit: number;
    download: boolean;
    sortBy: string;
    searchText: string;
    projectStateIds?: string[];
  }>({
    status: activeTab,
    offset: 0,
    limit: 300,
    download: false,
    sortBy: "updated_at:DESC",
    searchText: "",
    projectStateIds: undefined,
  });

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
  });

  const { isLoading, isFetching } = useQuery(
    ["users", queryParams],
    () =>
      getFundRequest({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const row = res?.data?.rows.map((row) => ({
          sno: row?.serialNo,
          id: row.id,
          costEstimateID: row.landOwnershipEpid,
          registrationNo: row.landOwnership.projectDetail.registrationNo,
          entityName: row.landOwnership.user.firstName,
          state: row.landOwnership?.projectDetail?.address?.stateDetail?.name,
          division: row.landOwnership.projectDetail.landSpecification.division,
          totalArea: `${row.landOwnership.netPlantationArea}`,
          ecoRestorationAmount: formatINR(
            row?.landOwnership?.demandNote ?? "-"
          ),

          estimatedAdvanceWorkCost: formatINR(row.estimatedAdvanceWorkCost),
          estimatedRaisingCost: formatINR(row.estimatedRaisingCost),
          estimatedMaintenanceCost: formatINR(row.estimatedMaintenanceCost),
          estimatedMonitoringCost: formatINR(row.estimatedMonitoringCost),
          estimatedContingencyCost: formatINR(row.estimatedContingencyCost),
          totalEstimatedCost: formatINR(row.totalEstimatedCost),

          advanceWorkCost: formatINR(row.advanceWorkCost),
          raisingCost: formatINR(row.raisingCost),
          maintenanceCost: formatINR(row.maintenanceCost),
          monitoringCost: formatINR(row.monitoringCost),
          contingencyCost: formatINR(row.contingencyCost),
          totalCost: formatINR(row.totalCost),
          status: fundStatus[row.status] ?? "-",

          created_at: dateAndTimeStamp(row.created_at, "IST"),
          updated_at: dateAndTimeStamp(row.updated_at, "IST"),

          remarks: row.remark,
        }));

        if (queryParams.download) {
          const exportData = row.map((item) => {
            return {
              "S.No": item?.sno,
              "FA-ID": item?.id,
              "Registration No": item?.registrationNo,
              "Entity Name": item.entityName,
              State: item?.state,
              Division: item?.division,
              "Net Planned Area (Ha)": item?.totalArea,
              "Eco-Restoration Cost (₹)": item?.ecoRestorationAmount,
              "Est.Advance Work Cost (₹)": item?.estimatedAdvanceWorkCost,
              "Est.Raising Cost (₹)": item?.estimatedRaisingCost,
              "Est.Maintenance Cost (₹)": item?.estimatedMaintenanceCost,
              "Est.Monitoring Cost (₹)": item?.estimatedMonitoringCost,
              "Est.Contingency Cost (₹)": item?.estimatedContingencyCost,
              "Est.Total Cost (₹)": item?.totalEstimatedCost,
              "Advance Work Cost (₹)": item?.advanceWorkCost,
              "Raising Cost (₹)": item?.raisingCost,
              "Maintenance Cost (₹)": item?.maintenanceCost,
              "Monitoring Cost (₹)": item?.monitoringCost,
              "Contingency Cost (₹)": item?.contingencyCost,
              "Total Cost (₹)": item?.totalCost,
              "Created At": item?.created_at,
              "Updated At": item?.updated_at,
              Remark: item?.remarks,
            };
          });
          downloadSheet(exportData);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setTableRows(row);
        }
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const RenderView = (params) => {
    return (
      <>
        <Box
          component="a"
          href={`${"/fund-application-details"}?${createSearchParams({
            id: params.row.id,
          })}`}
          sx={{ ...defaultStyles.renderButtonText2 }}
        >{`${params?.row?.id}`}</Box>
      </>
    );
  };

  const RenderEPID = (params) => {
    return (
      <>
        <Box
          component="a"
          href={`${path.landOwnershipDetails}?${createSearchParams({
            id: params?.row?.costEstimateID,
          })}`}
          sx={{ ...defaultStyles.renderButtonText2 }}
        >{`${params?.row?.costEstimateID}`}</Box>
      </>
    );
  };

  const tableColumns: any = [
    {
      field: "sno",
      headerName: "S.No",
      width: 90,
      sortable: false,
    },
    {
      field: "id",
      headerName: "FA-ID",
      width: 150,
      sortable: false,
      renderCell: RenderView,
    },
    {
      field: "costEstimateID",
      headerName: "Cost Estimate ID",
      width: 220,
      sortable: false,
      renderCell: RenderEPID,
    },
    {
      field: "registrationNo",
      headerName: "Reg.No",
      width: 100,
      sortable: false,
    },
    {
      field: "entityName",
      headerName: "Entity Name",
      width: 150,
      sortable: true,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      sortable: true,
    },
    {
      field: "division",
      headerName: "Division",
      width: 150,
      sortable: true,
      userType: ["INDIVIDUAL"],
      tab: [],
    },
    {
      field: "totalArea",
      headerName: "Net Planned Area(Ha)",
      width: 150,
      sortable: false,
    },
    {
      field: "ecoRestorationAmount",
      headerName: "Eco-Restoration Cost(₹)",
      width: 200,
      sortable: false,
    },
    {
      field: "estimatedAdvanceWorkCost",
      headerName: "Est.Advance Work Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "estimatedRaisingCost",
      headerName: "Est.Raising Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "estimatedMaintenanceCost",
      headerName: "Est.Maintenance Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "estimatedMonitoringCost",
      headerName: "Est.Monitoring Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "estimatedContingencyCost",
      headerName: "Est.Contingency Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "totalEstimatedCost",
      headerName: "Est.Total Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "advanceWorkCost",
      headerName: "Advance Work Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "raisingCost",
      headerName: "Raising Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "maintenanceCost",
      headerName: "Maintenance Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "monitoringCost",
      headerName: "Monitoring Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "contingencyCost",
      headerName: "Contingency Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "totalCost",
      headerName: "Total Cost(₹)",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "status",
      headerName: "Payment Receipt",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      sortable: true,
    },
    {
      field: "updated_at",
      headerName: "UPDATED AT",
      width: 150,
      sortable: true,
    },
  ];

  const exportToExcelEntity = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const handleSort = (sortByField) => {
    setQueryParams({
      ...queryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  return (
    <DashboardWrapper
      title="Fund Application (FA)"
      buttonIcon="/assets/svg/roundPlusIcon.svg"
      buttonText=""
      onButtonClick={() => {}}
      showBtn={!!tableRows.length}
    >
      <>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "2%",
              padding: "10px 1%",
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <SearchBar
              getText={(e) => {
                setQueryParams((a) => {
                  return { ...queryParams, searchText: `${e}` };
                });
              }}
              customStyles={{
                wrapper: {
                  width: "80%",
                },
              }}
            />
            <Box sx={{ display: "flex", gap: "8px" }}>
              <MultiSelect
                options={states}
                onChange={(e: any) => {
                  setFilterKey((prevFilter) => {
                    const newState = {
                      ...prevFilter,
                      projectStateIds: e.length ? e : null,
                    };

                    setQueryParams({
                      ...queryParams,
                      projectStateIds: newState?.projectStateIds,
                    });

                    return newState;
                  });
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              gap: "13%",
              padding: "10px 1%",
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <HorizontalTabs
              tabHeaders={fundPaymentTabs}
              tabIndex={fundPaymentTabs.findIndex(
                (item) => item.value === activeTab
              )}
              onChange={(e) => {
                setActiveTab(e);
                navigate(`${path.fundTransfer}${e ? `?currentTab=${e}` : ""}`);
                setQueryParams((prev) => ({
                  ...prev,
                  status: e,
                }));
              }}
              components={[<></>, <></>]}
            />
            <Button onClick={exportToExcelEntity} text={"Export"} />
          </Box>
          {/* Data Grid */}
          {isLoading || isFetching ? (
            <Box {...styles("flexContainer")}>
              <CircularProgress />
            </Box>
          ) : tableRows.length > 0 ? (
            <DataGrid
              rows={tableRows}
              columns={tableColumns}
              rowCount={tableRows.length}
              rowsPerPageOptions={[10]}
              sortingMode="server"
              onSortModelChange={handleSort}
              paginationMode="client"
              pageSize={50}
              columnBuffer={20}
              loading={isLoading}
              getRowId={(row) => `${row.id}-${row.userName}`}
            />
          ) : (
            <EmptyView
              heading="No Entry Found"
              subheading=""
              onClick={() => {}}
            />
          )}
        </Box>
      </>
    </DashboardWrapper>
  );
};

export default FundApplication;
