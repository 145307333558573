import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
};

export function SeedlingTable({ data, topHeader }: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  return (
    <TableContainer component={Paper} sx={{ height: "fit-content" }}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((seedData, index) => {
            if (!seedData) return null;
            return (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.commonName}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.botanicalName}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.numberOfSeedlings}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
