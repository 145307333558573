import { Box, Grid, Typography } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import { useForm } from "react-hook-form";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import ViewForm from "src/components/common/ViewForm";
import { formatToIndianCurrency } from "src/utils/helper";
import ViewImages from "src/components/common/ViewImage";
import { useReactToPrint } from "react-to-print";
import { useSearchParams } from "react-router-dom";
import {
  getLandDetailsById,
  getMointoringMember,
} from "src/utils/api/dashboard";
import { MonitoringMemberDetailsTable } from "src/components/common/DashBoardTable/monitoringMemberDetails";
import { FinalSubmitted } from "./FinalSubmittedView";

const MonitoringDetails = () => {
  const componentRef = useRef();

  const [searchParams] = useSearchParams();

  const landOwnershipEpid = searchParams.get("id");

  const styles = getStyles<StylesClasses>(defaultStyles);

  const TOPHEADER = [
    "S.no",
    "Name of member",
    "Designation",
    "Phone Number",
    "Email",
  ];

  const { data: userData } = useQuery(
    ["userDetails", landOwnershipEpid],
    () => getLandDetailsById(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!landOwnershipEpid,
      refetchOnMount: true,
    }
  );

  const { data: memberDetails } = useQuery(
    ["memberDetails", landOwnershipEpid],
    () => getMointoringMember(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      refetchOnMount: "always", // Refetches every time the component mounts
      refetchOnWindowFocus: true,
    }
  );

  const {
    control,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      landOwnershipEpid: "",
    },
  });

  const epiddata = userData?.data;

  return (
    <DashboardWrapper
      title={`Monitoring Details`}
      buttonText=""
      showBtn={true}
      showBackBtn={true}
    >
      <Box ref={componentRef} id="printable-content">
        <Box {...styles("formWrapper")}>
          <Box sx={{ width: "40%" }}>
            <ViewForm
              label={"Registration No"}
              value={epiddata?.projectDetail?.registrationNo}
            />
            <ViewForm label={"EPID"} value={epiddata?.id} />
            <ViewForm
              label={"State"}
              value={epiddata?.projectDetail?.address?.stateDetail?.name}
            />
            <ViewForm
              label={"District"}
              value={epiddata?.projectDetail?.address?.districtDetail?.name}
            />
            <ViewForm
              label={"Division"}
              value={epiddata?.projectDetail?.landSpecification?.division}
            />
            <ViewForm
              label={"Range"}
              value={epiddata?.projectDetail?.landSpecification?.range}
            />
            <ViewForm
              label={"Beat"}
              value={epiddata?.projectDetail?.landSpecification?.beat}
            />
          </Box>

          <Box sx={{ width: "40%" }}>
            <ViewForm
              label={"Total Area(ha)"}
              value={epiddata?.projectDetail?.landSpecification?.totalArea}
            />
            <ViewForm
              label={"Net Plantation Area(ha)"}
              value={epiddata?.netPlantationArea}
            />
            <ViewForm
              label={"Eco-Restoration Cost"}
              value={formatToIndianCurrency(epiddata?.demandNote)}
            />
          </Box>
          <Box sx={{ width: "30%" }}>
            <Box
              sx={{
                fontSize: "17px",
                fontWeight: "bold",
              }}
            >
              Technically sanctioned/approved cost estimate
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={epiddata?.paymentBreakdownPdfImage}
                  label={"PDF"}
                />
              </Box>
              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={epiddata?.paymentBreakdownImage}
                  label={"Ms Excel"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box {...styles("memberListHeading", { mt: "30px" })}>
          Committee Members
        </Box>
        <MonitoringMemberDetailsTable
          topHeader={TOPHEADER}
          data={memberDetails?.data.data}
        />
      </Box>
      <Box>
        <Box {...styles("memberListHeading", { margin: "30px 0px" })}>
          Monitoring{" "}
        </Box>
        <FinalSubmitted />
      </Box>
    </DashboardWrapper>
  );
};

export default MonitoringDetails;
