import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  getAllMonitoringDetailsData,
  getMointoringMember,
} from "src/utils/api/dashboard";
import { FencingView } from "../MonitoringView/fencing";
import { PlantationView } from "../MonitoringView/plantation";
import { SmcView } from "../MonitoringView/smcView";
import { IrrigationView } from "../MonitoringView/irrigationView";
import { LandPreparationView } from "../MonitoringView/landPreparationView";
import { NurseryView } from "../MonitoringView/nurseryView";
import { ProtectionView } from "../MonitoringView/protectionView";
import { JournalView } from "../MonitoringView/journalView";
import { OthersView } from "../MonitoringView/othersView";
import ViewForm from "src/components/common/ViewForm";
import defaultStyles from "./styles";
import { getStyles } from "src/styles/theme";
import ViewImages from "src/components/common/ViewImage";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import { MonitoringMemberDetailsTable } from "src/components/common/DashBoardTable/monitoringMemberDetails";
import { AxiosError, AxiosResponse } from "axios";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

export function FinalSubmittedComponentDetails() {
  const styles = getStyles(defaultStyles);

  const componentRef = useRef();

  const TOPHEADER = [
    "S.no",
    "Name of member",
    "Designation",
    "Phone Number",
    "Email",
  ];

  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const year = searchParams.get("year");
  const month = searchParams.get("monitoring");
  const queryParam = {
    year: year,
    month: month,
    landOwnershipEpid,
  };

  const { data } = useQuery(
    [year, month],
    () => getAllMonitoringDetailsData(queryParam),
    {
      onSuccess: (data: any) => {},
    }
  );

  const { data: memberDetails } = useQuery(
    ["memberDetails", landOwnershipEpid],
    () => getMointoringMember(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
    }
  );

  const smc = data?.data?.data.smcMonitoring;
  const fencing = data?.data?.data.fencingMonitoring;
  const irrigation = data?.data?.data.irrigationMonitoring;
  const landPreparation = data?.data?.data.landPreparationMonitoring;
  const nursery = data?.data?.data.nurseryMonitoring;
  const plantation = data?.data?.data.plantationMonitoring;
  const protection = data?.data?.data.protectionMonitoring;
  const journal = data?.data?.data.plantationJournalMonitoring;
  const othersMonitoring = data?.data?.data.otherMonitoring;
  const finalDocumented = data?.data?.data.finalReportDocumentUrl;
  const rating = data?.data?.data?.finalReport?.rating;
  const finalReport = data?.data?.data?.finalReport;

  const fencingImages = data?.data?.data.fencingMonitoringImages;
  const irrigationImages = data?.data?.data.irrigationMonitoringImages;
  const landPreparationImagees =
    data?.data?.data.landPreparationMonitoringImages;
  const journalImages = data?.data?.data.plantationJournalMonitoringImages;
  const nurseryImages = data?.data?.data.nurseryMonitoringImages;
  const othersImages = data?.data?.data.otherMonitoringImages;
  const plantationImages = data?.data?.data.plantationMonitoring
    ? data?.data?.data.plantationMonitoring.images
    : [];
  const protectionImages = data?.data?.data.protectionMonitoring?.images
    ? data?.data?.data.protectionMonitoring.images
    : [];
  const smcImages = data?.data?.data.smcMonitoringImages;

  const submitted = data?.data?.data.isFinalSubmitted;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  return (
    <DashboardWrapper
      title="Monitoring Details"
      customStyles={{
        container: { position: "relative" },
        children: { paddingBottom: "80px" },
      }}
      showBackBtn
      onButtonClick={handlePrint}
      buttonText={"Print"}
    >
      <Box
        ref={componentRef}
        id="printable-content"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          mt: "20px",
        }}
      >
        <Box {...styles("currentYearWrapper")}>
          <ViewForm label="EPID" value={landOwnershipEpid} />
          <ViewForm label="Year" value={year} />
          <ViewForm label="Monitoring" value={month} />
        </Box>
        <Box sx={{ mb: "20px" }}>
          <Box {...styles("subHeading")}>Committee Members</Box>
          <MonitoringMemberDetailsTable
            topHeader={TOPHEADER}
            data={memberDetails?.data.data}
          />
        </Box>
        {smc && (
          <Box>
            <Box {...styles("subHeading")}>Eco Restorations Operations</Box>
            <SmcView data={smc} images={smcImages} submitted={submitted} />
          </Box>
        )}
        {fencing && (
          <Box>
            <Box {...styles("subHeading")}>Fencing Operations</Box>
            <FencingView
              data={fencing}
              images={fencingImages}
              submitted={submitted}
            />
          </Box>
        )}

        {irrigation && (
          <Box>
            <Box {...styles("subHeading")}>Irrigation Operations</Box>
            <IrrigationView
              data={irrigation}
              images={irrigationImages}
              submitted={submitted}
            />
          </Box>
        )}

        {landPreparation && (
          <Box>
            <Box {...styles("subHeading")}>Land Preparations Activities</Box>
            <LandPreparationView
              data={landPreparation}
              images={landPreparationImagees}
            />
          </Box>
        )}

        {plantation && (
          <Box>
            <Box {...styles("subHeading")}>Planting Operations</Box>
            <PlantationView
              data={plantation}
              submitted={submitted}
              images={plantationImages}
            />
          </Box>
        )}

        {nursery && (
          <Box>
            <Box {...styles("subHeading")}>Nursery Operations</Box>
            <NurseryView
              data={nursery}
              images={nurseryImages}
              submitted={submitted}
            />
          </Box>
        )}

        {protection && (
          <Box>
            <Box {...styles("subHeading")}>Protection And Maintenance</Box>
            <ProtectionView
              data={protection}
              submitted={submitted}
              images={protectionImages}
            />
          </Box>
        )}

        {journal && (
          <Box>
            <Box {...styles("subHeading")}>Planting Journal</Box>
            <JournalView
              data={journal}
              images={journalImages}
              submitted={submitted}
            />
          </Box>
        )}

        {othersMonitoring && (
          <Box>
            <Box {...styles("subHeading")}>Other Operations</Box>
            <OthersView
              data={othersMonitoring}
              images={othersImages}
              submitted={submitted}
            />
          </Box>
        )}
        {finalDocumented && (
          <Box>
            <Box {...styles("subHeading")}>
              {" "}
              Final Document Report and Rating
            </Box>
            <ViewForm label={"Rating"} value={rating} />
            <ViewForm
              label="Overall Observations"
              value={finalReport?.remark}
            />

            <Box {...styles("formWrapper")}>
              <ViewImages
                fileUrl={finalDocumented}
                label={"Monitoring Report PDF"}
              />
            </Box>
          </Box>
        )}
      </Box>
    </DashboardWrapper>
  );
}
