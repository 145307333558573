import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents";
import { MenuOption } from "src/components/common/MenuOption";
import { DeleteModal, SuccessModal } from "src/components/common/modal";
import { ROLES, userTabData } from "src/constants/common";
import {
  deleteUser,
  getAllUser,
  getUserDashboardDetails,
  userStatus,
} from "src/utils/api/dashboard";
import { dateAndTimeStamp, downloadSheet } from "src/utils/helper";
import defaultStyles from "./styles";
import SearchBar from "src/components/common/SearchField";

import StatsCard from "src/components/common/StatsCard";
import { getStyles } from "src/styles/theme";

const Users = () => {
  const [searchState, setSearchState] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const userTabs = userTabData[1].value;

  const [userData, setUserData] = useState("");

  const [queryParams, setQueryParams] = useState<{
    search?: string;
    role: string;
  }>({
    search: "",
    role: "Entity/ Green Credit Applicant",
  });

  const styles = getStyles(defaultStyles);

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    type: "",
    btnText2: "",
    btnText: "",
  });

  const [error, setError] = useState({
    open: false,
    heading: "",

    type: "",
  });

  const { isLoading, refetch, isFetching } = useQuery(
    ["users", queryParams],
    () => getAllUser(queryParams),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res.data.rows.map((user, idx) => ({
            // id: `${user.id}-${idx}`,
            userId: user.id,
            sno: idx + 1,
            name: user?.firstName,
            entityType: user.entityType,
            nameOfAuthorizedPerson: user?.nameOfAuthorizedPerson,
            designation: user.designation,
            email: user.email,
            phone: `${user.countryCode ?? "+91"} ${user.phoneNumber}`,
            userName: user.userName,
            status: user.status.status,
            createdAt: dateAndTimeStamp(user.createdAt, "IST"),
            role:
              user.role.name === "Implementing Agency"
                ? ROLES[user.userType] === "Individual"
                  ? "Nodal Officer"
                  : "Implementing Officer"
                : ROLES[user.userType],
          }))
        );
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const { data: dashboard } = useQuery(
    ["userDashboard"],
    () => getUserDashboardDetails(),
    {
      onSuccess: () => {},
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const { mutate } = useMutation(
    "changeStatus",
    (data: { status: string; userId: string }) => userStatus(data),
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {},
    }
  );

  const deleteUserFun = useMutation(
    "deleteUSer",
    (data: string) => deleteUser(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setError({
          open: true,
          heading: "User Successfully Deleted",
          type: "success",
        });
        refetch();
      },
      onError: (err: AxiosError) => {
        setError({
          open: true,
          heading: err.response.data.message,
          type: "",
        });
        refetch();
      },
    }
  );

  const handleMenuOptions = (params, menuItem) => {
    mutate({
      status: menuItem.value,
      userId: params.row.userId,
    });
  };

  const RenderButton = (params) => {
    const navigate = useNavigate();
    return (
      <Button
        text={`${params?.row?.name}`}
        variant="text"
        onClick={() => {
          navigate("/user-details", {
            state: {
              userId: params?.row?.userId,
            },
          });
        }}
        customStyles={{
          text: defaultStyles.renderButtonText,
        }}
      />
    );
  };

  const DeleteUser = (params) => {
    return (
      <Button
        text="Delete"
        variant="text"
        onClick={() => {
          setUserData(params.row.userId);
          setOpenModal({
            open: true,
            subheading: params.row.name,
            heading: "",
            btnText: "Yes",
            btnText2: "No",
            type: "success",
          });
        }}
        customStyles={{
          text: defaultStyles.deleteUser,
        }}
      />
    );
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  const handleConfirmationModalClose = () => {
    setError({ ...error, open: false });
  };

  const handleResponseModal = () => {
    setOpenModal({ ...openModal, open: false });
    deleteUserFun.mutate(userData);
  };

  const entityTableColumns: GridColDef[] = [
    { field: "id", headerName: "id", width: 75, sortable: false, hide: true },
    {
      field: "sno",
      headerName: "S No.",
      width: 75,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Entity Name",
      width: 400,
      sortable: false,
      renderCell: RenderButton,
    },
    {
      field: "entityType",
      headerName: "Entity Type",
      width: 300,
      sortable: false,
    },
    {
      field: "nameOfAuthorizedPerson",
      headerName: "Name of authorized person",
      width: 250,
      sortable: false,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 200,
      sortable: false,
    },
    { field: "userName", headerName: "User Name", width: 175, sortable: false },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      sortable: false,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 190,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Date of Registration",
      width: 200,
      sortable: false,
    },
    {
      field: "menuItem",
      headerName: "Active/Inactive",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <MenuOption
          options={[
            { label: "Active", value: "ACTIVE", isActive: true },
            { label: "Inactive", value: "INACTIVE", isActive: false },
          ]}
          handleMenuClick={(item) => handleMenuOptions(params, item)}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete User",
      width: 150,
      sortable: false,
      renderCell: DeleteUser,
    },
  ];

  const exportToExcel2 = () => {
    downloadSheet(tableRows);
  };

  return (
    <>
      <DashboardWrapper
        title="Entity/ GCA User Details"
        buttonIcon="/assets/svg/roundPlusIcon.svg"
        buttonText=""
        onButtonClick={() => {}}
        showBtn={!!tableRows.length}
      >
        <Box {...styles("statsCardWrapper", { marginTop: "20px" })}>
          {dashboard?.data?.stats?.map((stat, index) => (
            <StatsCard
              key={index}
              title={`${stat?.description?.toUpperCase()}`}
              value={`${stat?.number}`}
              customStyles={{ valueCard: styles("statsCard").sx }}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: "2%",
            padding: "10px 1%",
            background: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <SearchBar
            getText={(e) => {
              setQueryParams((a) => {
                return { ...queryParams, search: `${e}` };
              });
            }}
            placeHolder="Search by entity name"
            customStyles={{ wrapper: defaultStyles.searchBarWrapper }}
          />
          <Button onClick={exportToExcel2} text={"Export"} />
        </Box>
        <>
          <>
            {isLoading || isFetching ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : !!tableRows.length ? (
              <DataGrid
                rows={tableRows || []}
                columns={entityTableColumns}
                rowCount={tableRows.length}
                rowsPerPageOptions={[10]}
                paginationMode="client"
                pageSize={50}
                getRowId={(row) => `${row.id}-${row.userName}`}
              />
            ) : (
              <EmptyView
                heading="No user found"
                subheading=""
                onClick={() => {}}
              />
            )}
          </>
        </>
      </DashboardWrapper>
      <DeleteModal
        open={openModal.open}
        heading={"Are you sure you want to delete this user?"}
        subheading={openModal.subheading}
        btnText={openModal.btnText}
        btnText2={openModal.btnText2}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      ></DeleteModal>
      <SuccessModal
        open={error.open}
        heading={error.heading}
        type={error.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleConfirmationModalClose()}
      ></SuccessModal>
    </>
  );
};

export default Users;
