import { Box, Button, Collapse, Typography } from "@mui/material";
import { useQuery } from "react-query";
import StatsCard from "src/components/common/StatsCard";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import defaultStyles from "../styles";
import { getStyles } from "src/styles/theme";
import {
  getAllUser,
  getEntityDashboardDetails,
  getTableDashboardDetails,
  getUserDashboardDetails,
} from "src/utils/api/dashboard";
import { DashBoardTable } from "src/components/common/DashBoardTable";
import {
  downloadSheet,
  exportEntityTableToXlsx,
  extractStates,
  formatData,
  formatDataForBarChart,
} from "src/utils/helper";
import Fallback from "src/components/common/Fallback";
import { useEffect, useState } from "react";
import RenderTable from "../../Home/RenderTable";
import { AxiosError, AxiosResponse } from "axios";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import { entityDashboardtableColumns } from "src/constants/tableColumns";
import BarChartComponent from "src/components/common/BarComponent";
import { EntityDashBoardTable } from "src/components/common/DashBoardTable/entityDashboard";

const Entity = () => {
  const [isTabLoading, setIsTabLoading] = useState(true);
  const [topHeader, setTopHeader] = useState([""]);
  const [header, setHeader] = useState([""]);
  const [tableRows, setTableRows] = useState([]);
  const [filtersState, setFiltersState] = useState([]);
  const [filterEntities, setfilterEntities] = useState([]);

  const [states, setStates] = useState<any>([]);

  const [queryParams, setQueryParams] = useState<{
    offset: number;
    limit: number;
    sortBy: string;
    searchText: string;
    stateIds?: string[];
    entityIds?: string[];
    type: string;
  }>({
    offset: 0,
    limit: 50,
    sortBy: "state:ASC",
    searchText: "",
    stateIds: undefined,
    entityIds: undefined,
    type: "entityLandOwnership",
  });

  const [usertQueryParams, setUserQueryParams] = useState<{
    role: string;
    status: string;
  }>({
    role: "Entity/ Green Credit Applicant",
    status: "ACTIVE",
  });

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
  });

  const [entityfilterKey, entitySetFilterKey] = useState({
    enetityStateIds: null,
  });

  const { data: userData } = useQuery(
    ["users", usertQueryParams],
    () => getAllUser(usertQueryParams),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const entityInfo = userData?.data?.rows?.map((item) => {
    return {
      id: item.id,
      name: item.firstName,
    };
  });

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const { isLoading: entityTableLoading } = useQuery(
    ["entityTableDashboard", queryParams],
    () => getEntityDashboardDetails({ ...queryParams, type: "table" }),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res?.data?.stats.map((items, idx) => ({
            id: idx,
            sno: idx + 1,
            entityName: items.entity,
            state: items.state,
            totalBlocks: items.totalNumber,
            totalBlocksArea: items.totalArea,
            numberDemandNotePending: items.totalNumberOfGenerateDemandNote,
            areaDemandNotePending: items.totalAreaOfGenerateDemandNote,
            numberPaymentPending: items.totalNumberOfPaymentPending,
            areaPaymentPending: items.totalAreaOfPaymentPending,
            numberPaymentDone: items.totalNumberOfPaymentDone,
            areaPaymentDone: items.totalAreaOfPaymentDone,
            totalAreaOfGenerateDemandNote: items.totalAreaOfGenerateDemandNote,
            totalNumberOfGenerateDemandNote:
              items.totalNumberOfGenerateDemandNote,
            totalAreaOfVerificationPending:
              items.totalAreaOfVerificationPending,
            totalNumberOfVerificationPending:
              items.totalNumberOfVerificationPending,
            totalAreaOfDemandNotePending: items.totalAreaOfDemandNotePending,
            totalNumberOfDemandNotePending:
              items.totalNumberOfDemandNotePending,
            totalAreaOfDemandNoteReleased: items.totalAreaOfDemandNoteReleased,
            totalNumberOfDemandNoteReleased:
              items.totalNumberOfDemandNoteReleased,
          }))
        );
      },
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const { data: entityDashboard, isLoading: entityDashboardLoading } = useQuery(
    ["entityDashboard", queryParams],
    () => getEntityDashboardDetails({ ...queryParams, type: "boxes" }),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: () => {},
      refetchOnMount: true,
    }
  );

  const formattedData = formatDataForBarChart(entityDashboard?.data?.stats);

  const dashboardTableData: DashBoardTable = {
    topHeader: [
      "",
      "All Selected Blocks",
      "Cost Est. Pending From SFD",
      "Cost Est. Verification Pending",
      "Cost Est. Received From SFD",
      "Demand Note Released",
      "Payment Pending",
      "Payment Received ",
    ],
    header: [
      "S.No",
      "Entity Name",
      "State",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
      "No",
      "Area (ha)",
    ],
    data: tableRows,
  };

  const styles = getStyles(defaultStyles);
  useEffect(() => {
    setIsTabLoading(true);
  }, []);
  const [tableState, setTableState] = useState(false);

  useEffect(() => {
    setFiltersState(entityDashboard?.data?.state);
    setfilterEntities(entityDashboard?.data.entities);
  }, [entityDashboard]);

  return (
    <>
      <Box {...styles("topHeader")}>
        <Box
          sx={{
            marginBottom: "70px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {filtersState && (
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                Selected States:-
                {filtersState?.map((filterState) => {
                  return (
                    <Box
                      sx={{
                        height: "46px",
                        padding: "11px",
                        width: "fit-content",
                        border: "1px solid grey",
                        borderRadius: "24px",
                        backgroundColor: "#eee",
                      }}
                    >
                      {filterState}
                    </Box>
                  );
                })}
              </Box>
            )}
            {filterEntities && (
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                Selected Entities:-
                {filterEntities?.map((filterState) => {
                  return (
                    <Box
                      sx={{
                        height: "46px",
                        padding: "11px",
                        width: "fit-content",
                        border: "1px solid grey",
                        borderRadius: "24px",
                        backgroundColor: "#eee",
                      }}
                    >
                      {filterState}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <MultiSelect
              options={states}
              onChange={(e: any) => {
                setFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    projectStateIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    stateIds: newState?.projectStateIds,
                  });

                  return newState;
                });
              }}
            />
            <MultiSelect
              options={entityInfo}
              label={"Filter By Entity"}
              onChange={(e: any) => {
                entitySetFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    entityIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    entityIds: newState?.entityIds,
                  });

                  return newState;
                });
              }}
            />
          </Box>
        </Box>
        {!entityDashboardLoading ? (
          <Box sx={{ width: "75vw", marginBottom: "20px" }}>
            <Box sx={{ width: "80%", marginRight: "60px" }}>
              <BarChartComponent
                data={formattedData}
                label={"Status of Eco-Restoration Blocks Selected by Entity"}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box {...styles("statsCardWrapper", { marginTop: "20px" })}>
          {entityDashboard?.data?.stats?.map((stat, index) => (
            <StatsCard
              key={index}
              title={`${stat?.description?.toUpperCase()}`}
              value={`${stat?.number}`}
              area={stat?.totalArea}
              customStyles={{ valueCard: styles("statsCard").sx }}
            />
          ))}
        </Box>
      </Box>

      {entityTableLoading ? (
        <Fallback />
      ) : (
        <Box {...styles("dashboardTableWrapper")}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" {...styles("heading")}>
              Status of Selected Eco-Restoration Blocks under GCP
            </Typography>
            <Button
              variant="outlined"
              onClick={() => setTableState(!tableState)}
            >
              {tableState ? (
                <>
                  Compress
                  <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
                </>
              ) : (
                <>
                  Expend
                  <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
                </>
              )}
            </Button>
          </Box>
          <Collapse in={tableState}>
            <Button
              variant="outlined"
              onClick={() =>
                exportEntityTableToXlsx(
                  dashboardTableData.data,
                  dashboardTableData.header,
                  dashboardTableData.topHeader
                )
              }
              sx={{ float: "right", marginBottom: "20px" }}
            >
              Export
            </Button>
            <EntityDashBoardTable {...dashboardTableData} />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default Entity;
