import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";

export type DashBoardTable = {
  topHeader: string[];
  header: string[];
  data: any;
};

export function SelectedLandTable({ data, header, topHeader }: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell
                align="center"
                {...styles("cell")}
                key={index}
                colSpan={2}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {header.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow>
                <TableCell align="center" {...styles("cell")}>
                  {row.sno}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "180px" })}
                >
                  {row.state}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalNumberRegistered}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalAreaRegistered}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalNumberApproved}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalAreaApproved}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalNumberSelected}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalAreaSelected}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalNumberAvailable}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.totalAreaAvailable}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
