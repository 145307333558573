import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents";
import { MenuOption } from "src/components/common/MenuOption";
import { DeleteModal, SuccessModal } from "src/components/common/modal";
import { ROLES, userTabData } from "src/constants/common";
import { deleteUser, getAllUser, userStatus } from "src/utils/api/dashboard";
import { dateAndTimeStamp, downloadSheet } from "src/utils/helper";
import defaultStyles from "./styles";
import { useAppSelector } from "src/redux/store";
import SearchBar from "src/components/common/SearchField";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";

const ImplementingAgency = () => {
  const [tableRows, setTableRows] = useState([]);
  const userTabs = userTabData[0].value;

  const user = useAppSelector((state) => state.root.user);

  const [userData, setUserData] = useState("");

  const [states, setStates] = useState<any>([]);

  const [filterKey, setFilterKey] = useState({
    stateIds: null,
  });

  const [queryParams, setQueryParams] = useState<{
    search?: string;
    role: string;
    stateIds: string;
  }>({
    search: "",
    role: "Implementing Agency",
    stateIds: "",
  });

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    type: "",
    btnText2: "",
    btnText: "",
  });

  const [error, setError] = useState({
    open: false,
    heading: "",
    type: "",
  });

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const { isLoading, refetch, isFetching } = useQuery(
    ["users", queryParams],
    () => getAllUser({ ...queryParams }),
    {
      onSuccess: (res: AxiosResponse) => {
        setTableRows(
          res.data.rows.map((user, idx) => ({
            userId: user.id,
            sno: idx + 1,
            name: user?.firstName,
            designation: user?.designation,
            role:
              user?.role?.name === "Implementing Agency"
                ? ROLES[user.userType] === "Individual"
                  ? "Nodal Officer"
                  : "Implementing Officer"
                : ROLES[user.userType],
            state: user?.address?.state,
            division: user?.division ?? "-",
            userName: user?.userName,
            email: user?.email,
            phone: `${user.countryCode ?? "+91"} ${user.phoneNumber}`,
            status: user?.status.status,
            createdAt: dateAndTimeStamp(user.createdAt, "IST"),
          }))
        );
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const { mutate } = useMutation(
    "changeStatus",
    (data: { status: string; userId: string }) => userStatus(data),
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {},
    }
  );

  const deleteUserFun = useMutation(
    "deleteUSer",
    (data: string) => deleteUser(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setError({
          open: true,
          heading: "User Successfully Deleted",
          type: "success",
        });
        refetch();
      },
      onError: (err: AxiosError) => {
        setError({
          open: true,
          heading: err.response.data.message,
          type: "",
        });
        refetch();
      },
    }
  );

  const handleMenuOptions = (params, menuItem) => {
    mutate({
      status: menuItem.value,
      userId: params.row.userId,
    });
  };

  const RenderButton = (params) => {
    const navigate = useNavigate();
    return (
      <Button
        text={`${params?.row?.name}`}
        variant="text"
        onClick={() => {
          navigate("/user-details", {
            state: {
              userId: params?.row?.userId,
            },
          });
        }}
        customStyles={{
          text: defaultStyles.renderButtonText,
        }}
      />
    );
  };

  const DeleteUser = (params) => {
    return (
      <Button
        text="Delete"
        variant="text"
        onClick={() => {
          setUserData(params.row.userId);
          setOpenModal({
            open: true,
            subheading: params.row.name,
            heading: "",
            btnText: "Yes",
            btnText2: "No",
            type: "success",
          });
        }}
        customStyles={{
          text: defaultStyles.deleteUser,
        }}
      />
    );
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  const handleConfirmationModalClose = () => {
    setError({ ...error, open: false });
  };

  const handleResponseModal = () => {
    setOpenModal({ ...openModal, open: false });
    deleteUserFun.mutate(userData);
  };

  const tableColumns: any = [
    {
      field: "id",
      headerName: "id",
      width: 75,
      sortable: false,
      hide: true,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "sno",
      headerName: "S No.",
      width: 75,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      sortable: false,
      renderCell: RenderButton,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 200,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "role",
      headerName: "Role (SNO/DIO)",
      width: 200,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "state",
      headerName: "state",
      width: 200,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "division",
      headerName: "Division",
      width: 200,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 175,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 200,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "createdAt",
      headerName: "Date of Registration",
      width: 250,
      sortable: false,
      user: ["Approver-view-only", "Approver"],
    },
    {
      field: "menuItem",
      headerName: "Active/Inactive",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <MenuOption
          options={[
            { label: "Active", value: "ACTIVE", isActive: true },
            { label: "Inactive", value: "INACTIVE", isActive: false },
          ]}
          handleMenuClick={(item) => handleMenuOptions(params, item)}
        />
      ),
      user: ["Approver"],
    },
    {
      field: "delete",
      headerName: "Delete User",
      width: 150,
      sortable: false,
      renderCell: DeleteUser,
      user: ["Approver"],
    },
  ];

  const columns = useMemo(() => {
    return tableColumns.filter((item) => {
      return item.user.includes(user.data.role);
    });
  }, [user]);

  const exportToExcel2 = () => {
    downloadSheet(tableRows);
  };

  return (
    <>
      <DashboardWrapper
        title="Implementing Agency User Details"
        buttonIcon="/assets/svg/roundPlusIcon.svg"
        buttonText=""
        onButtonClick={() => {}}
        showBtn={!!tableRows.length}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: "2%",
            padding: "10px 1%",
            background: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <SearchBar
            placeHolder="Search by name"
            getText={(e) => {
              setQueryParams((a) => {
                return { ...queryParams, search: `${e}` };
              });
            }}
            customStyles={{ wrapper: defaultStyles.searchBarWrapper }}
          />
          <Button onClick={exportToExcel2} text={"Export"} />
        </Box>
        <>
          {isLoading || isFetching ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : !!tableRows.length ? (
            <DataGrid
              rows={tableRows || []}
              columns={columns}
              rowCount={tableRows.length}
              rowsPerPageOptions={[10]}
              paginationMode="client"
              pageSize={50}
              getRowId={(row) => `${row.id}-${row.userName}`}
            />
          ) : (
            <EmptyView
              heading="No user found"
              subheading=""
              onClick={() => {}}
            />
          )}
        </>
      </DashboardWrapper>
      <DeleteModal
        open={openModal.open}
        heading={"Are you sure you want to delete this user?"}
        subheading={openModal.subheading}
        btnText={openModal.btnText}
        btnText2={openModal.btnText2}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      ></DeleteModal>
      <SuccessModal
        open={error.open}
        heading={error.heading}
        type={error.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleConfirmationModalClose()}
      ></SuccessModal>
    </>
  );
};

export default ImplementingAgency;
