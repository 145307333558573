import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useQuery } from "react-query";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { path } from "src/constants/path";
import { getFinalSubmittedComponentDetails } from "src/utils/api/dashboard";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";

export function FinalSubmitted({ year, month }: any) {
  const styles = getStyles(defaultStyles);
  const [searchParams] = useSearchParams();
  const landOwnershipEpid = searchParams.get("id");
  const { data } = useQuery(
    ["finalSubmitted"],
    () => getFinalSubmittedComponentDetails(landOwnershipEpid),
    {
      onSuccess: ({ data }: any) => {
        console.log("finalSubmit data", data);
      },
    }
  );

  return (
    <Box sx={{ mt: "20px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell {...styles("headingRow")}>Year</TableCell>
              <TableCell {...styles("headingRow")}>Month</TableCell>
              <TableCell {...styles("headingRow")}>View Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.data.map((item: any, index: number) => (
              <TableRow key={`${item.year}-${index}`}>
                <TableCell {...styles("row")}>{item.year}</TableCell>
                <TableCell {...styles("row")}>{item.month}</TableCell>
                <TableCell {...styles("row")}>
                  <Button
                    component="a"
                    href={`${path.submittedMonitorings}?${createSearchParams({
                      id: landOwnershipEpid,
                      year: item.year,
                      monitoring: item.month,
                    })}`}
                    // target="_blank"
                    variant="contained"
                    size="small"
                  >
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
