import { Box, Dialog } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import { useForm } from "react-hook-form";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import ViewForm from "src/components/common/ViewForm";
import { formatToIndianCurrency } from "src/utils/helper";
import ViewImages from "src/components/common/ViewImage";
import { useReactToPrint } from "react-to-print";
import { useSearchParams } from "react-router-dom";
import { UpdateProgress } from "src/components/common/DashBoardTable/updateProgress";
import { SeedlingTable } from "src/components/common/DashBoardTable/seedlingTable";
import {
  CostEstimateSummary,
  DashBoardTable,
} from "src/components/common/DashBoardTable/costEstimateSummary";
import {
  getCostEstimateDetails,
  getLandDetailsById,
} from "src/utils/api/dashboard";
import { AddProgress } from "./EditCostEstimateForm";

const AdvanceWorkProgress = () => {
  const componentRef = useRef();

  const [searchParams] = useSearchParams();

  const landOwnershipEpid = searchParams.get("id");

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [yearTableRows, setYearTableRows] = useState({});

  const [yearSummaryTableRows, setYearSummaryTableRows] = useState([]);
  const [seedlingTableData, setSeedlingTableData] = useState([]);

  const [rowToDelete, setRowToDelete] = useState(null);

  const [showForm, setShowForm] = useState(false);

  const { data: userData } = useQuery(
    ["userDetails", landOwnershipEpid],
    () => getLandDetailsById(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!landOwnershipEpid,
      refetchOnMount: true,
    }
  );

  const mapYearData = (yearTargets) => {
    return yearTargets?.map((row) => ({
      data: row,
      id: row.id,
      sno: row.serialNumber,
      year: row?.year,
      costEstimateID: row?.landOwnershipEpid,
      mainComponent: row?.indicator?.name,
      subComponent: row?.activity?.name,
      perticularOfItem: row?.perticularOfItem,
      unit: row?.unit,
      costPerUnit: row?.costPerUnit,
      numberOfUnits: row?.numberOfUnits,
      costRequired: row?.costRequired,
      progressInUnits: row?.progressInUnits,
      costProgress: row?.costProgress,
      status: row?.status,
    }));
  };

  const { data: targetData, refetch } = useQuery(
    ["costEstimateDetails", landOwnershipEpid],
    () =>
      getCostEstimateDetails({
        landOwnershipEpid,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const yearData = {
          firstYear: mapYearData(res?.data?.data?.firstYearTargets),
          secondYear: mapYearData(res?.data?.data?.secondYearTargets),
          thirdYear: mapYearData(res?.data?.data?.thirdYearTargets),
          fourthYear: mapYearData(res?.data?.data?.fourthYearTargets),
          fifthYear: mapYearData(res?.data?.data?.fifthYearTargets),
          sixthYear: mapYearData(res?.data?.data?.sixthYearTargets),
          seventhYear: mapYearData(res?.data?.data?.seventhYearTargets),
          eighthYear: mapYearData(res?.data?.data?.eighthYearTargets),
          ninthYear: mapYearData(res?.data?.data?.ninthYearTargets),
          tenthYear: mapYearData(res?.data?.data?.tenthYearTargets),
          other: mapYearData(res?.data?.data?.otherNecessaryProvisions),
        };
        setYearSummaryTableRows([
          res?.data?.data?.firstYearTotalCostRequired,
          res?.data?.data?.secondYearTotalCostRequired,
          res?.data?.data?.thirdYearTotalCostRequired,
          res?.data?.data?.fourthYearTotalCostRequired,
          res?.data?.data?.fifthYearTotalCostRequired,
          res?.data?.data?.sixthYearTotalCostRequired,
          res?.data?.data?.seventhYearTotalCostRequired,
          res?.data?.data?.eighthYearTotalCostRequired,
          res?.data?.data?.ninthYearTotalCostRequired,
          res?.data?.data?.tenthYearTotalCostRequired,
          res?.data?.data?.otherNecessaryProvisionsTotalCostRequired,
        ]);
        setSeedlingTableData(res?.data?.data?.seedlings);
        setYearTableRows(yearData);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const {
    control,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      landOwnershipEpid: "",
    },
  });

  const commonTableHeaders = [
    "Year",
    "Main Component",
    "Sub Component",
    "Particulars of item of works",
    "Unit",
    "No of Unit Required",
    "Cost Required(Rs.)",
    "Physical Progress",
    "Financial Progress",
  ];

  const commonSummaryHeaders = ["Year", "Cost Required"];
  const commonSeedlingHeader = [
    "Common Name",
    "Botanical Name",
    "No of Seedlings",
  ];

  const [RowData, setRowData] = useState();
  const [editFormState, setEditFormState] = useState<boolean>(false);
  const handleCostEstimateTableEdit = (row) => {
    setEditFormState(true);
    setRowData(row);
  };

  const renderYearlyTables = () => {
    return Object.keys(yearTableRows).map((yearKey, index) => {
      if (yearKey === "yearSummary") return;
      const tableData: DashBoardTable = {
        topHeader: commonTableHeaders,
        data: yearTableRows[yearKey],
      };

      if (yearTableRows[yearKey]?.length > 0) {
        return (
          <Box key={yearKey} sx={{ marginBottom: "30px", marginTop: "30px" }}>
            <UpdateProgress
              {...tableData}
              onEdit={handleCostEstimateTableEdit}
              status={targetData?.data?.data?.status}
            />
          </Box>
        );
      }
      return null;
    });
  };

  const renderSummaryTable = () => {
    const summaryData: DashBoardTable = {
      topHeader: commonSummaryHeaders,
      data: yearSummaryTableRows,
    };
    const seedlingData: DashBoardTable = {
      topHeader: commonSeedlingHeader,
      data: seedlingTableData,
    };

    if (yearSummaryTableRows) {
      return (
        <Box
          sx={{
            marginBottom: "30px",
            marginTop: "30px",
            display: "flex",
            gap: "20px",
          }}
        >
          <CostEstimateSummary
            {...summaryData}
            onEdit={handleCostEstimateTableEdit}
            status={targetData?.data?.data?.status}
          />
          <SeedlingTable {...seedlingData} />
        </Box>
      );
    }
  };

  const bottomRef = useRef(null);

  const moveToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRedirect = () => {
    moveToBottom();
    setShowForm(true);
  };

  const handleAddForm = () => {
    setShowForm(false);
    refetch();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const epiddata = userData?.data;

  return (
    <>
      <DashboardWrapper
        title="Progress Details"
        buttonText="Print"
        onButtonClick={handlePrint}
        showBtn={true}
        showBackBtn={true}
      >
        <>
          <Box>
            <Box ref={componentRef} id="printable-content">
              <Box
                sx={{
                  fontSize: "22px",
                  textAlign: "center",
                  marginTop: "26px",
                  fontWeight: "bold",
                }}
              >
                Cost Estimate for eco-restoration of degraded forest lands under
                the Green Credit Programme
              </Box>
              <Box {...styles("formWrapper")}>
                <Box sx={{ width: "40%" }}>
                  <ViewForm
                    label={"Registration No"}
                    value={epiddata?.projectDetail?.registrationNo}
                  />
                  <ViewForm label={"EPID"} value={epiddata?.id} />
                  <ViewForm
                    label={"State"}
                    value={epiddata?.projectDetail?.address?.stateDetail?.name}
                  />
                  <ViewForm
                    label={"District"}
                    value={
                      epiddata?.projectDetail?.address?.districtDetail?.name
                    }
                  />
                  <ViewForm
                    label={"Division"}
                    value={epiddata?.projectDetail?.landSpecification?.division}
                  />
                  <ViewForm
                    label={"Range"}
                    value={epiddata?.projectDetail?.landSpecification?.range}
                  />
                  <ViewForm
                    label={"Beat"}
                    value={epiddata?.projectDetail?.landSpecification?.beat}
                  />
                </Box>

                <Box sx={{ width: "40%" }}>
                  <ViewForm
                    label={"Total Area(ha)"}
                    value={
                      epiddata?.projectDetail?.landSpecification?.totalArea
                    }
                  />
                  <ViewForm
                    label={"Net Plantation Area(ha)"}
                    value={epiddata?.netPlantationArea}
                  />
                  <ViewForm
                    label={"Eco-Restoration Cost"}
                    value={formatToIndianCurrency(epiddata?.demandNote)}
                  />
                  {targetData?.data?.data?.plantationPlanning?.pitSize && (
                    <>
                      <ViewForm
                        label={"Sapling spacing(m X m)"}
                        value={`${targetData?.data?.data?.plantationPlanning?.treeSeedlingPacingX} X ${targetData?.data?.data?.plantationPlanning?.treeSeedlingPacingY}`}
                      />
                      <ViewForm
                        label={"Pit Size"}
                        value={
                          targetData?.data?.data?.plantationPlanning?.pitSize
                        }
                      />
                      <ViewForm
                        label={"Total No. of Saplings"}
                        value={
                          targetData?.data?.data?.plantationPlanning
                            ?.totalNumberOfSeedlingToBePlanted
                        }
                      />
                    </>
                  )}
                </Box>

                <Box sx={{ width: "30%" }}>
                  <Box
                    sx={{
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    Technically sanctioned/approved cost estimate
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={epiddata?.paymentBreakdownPdfImage}
                        label={"PDF"}
                      />
                    </Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={epiddata?.paymentBreakdownImage}
                        label={"Ms Excel"}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {Object.values(yearTableRows).flat().length > 0 ? (
                <>
                  {/* Render all year tables */}
                  {renderSummaryTable()}
                  {renderYearlyTables()}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </>
        <Dialog
          open={editFormState}
          onClose={() => {
            setEditFormState(false);
            refetch();
          }}
          fullWidth
          maxWidth="lg"
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              borderRadius: "16px",
            },
          }}
        >
          <AddProgress
            rowData={RowData}
            selfState={setEditFormState}
            refetch={refetch}
          />
        </Dialog>
      </DashboardWrapper>
    </>
  );
};

export default AdvanceWorkProgress;
