import { Box, CircularProgress, Divider } from "@mui/material";
import { usePDF } from "@react-pdf/renderer";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Card from "src/components/common/Card";
import Checkbox from "src/components/common/FormComponents/Checkbox";

import MapComponent from "src/components/common/KML";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { ProjectType, PROJECT_DOCUMENT_TYPE_MAP } from "src/constants/common";
import { CustomStyles, getStyles } from "src/styles/theme";
import {
  addProjectToIo,
  getIOdDetails,
  getMyProfileData,
  getProjectById,
} from "src/utils/api/dashboard";
import {
  dateAndTimeStamp,
  downloadSheet,
  getFormattedDate,
} from "src/utils/helper";
import defaultStyles, { StylesClasses } from "../styles";
import TreePdf from "./TreePdf";
import { Button } from "src/components/common/FormComponents/Buttons";
import AutoComplete from "src/components/common/FormComponents/AutoComplete";

type TreeProjectProps = {
  projectId: string;
  customStyles?: CustomStyles<StylesClasses>;
};

export const ProjectDetailQuery = "projects";

const TreeProject = ({ projectId, customStyles }: TreeProjectProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [projectData, setProjectData] = useState<any>([]);
  const [documents, setDocuments] = useState<any>();
  // const [pfdLink, setPdfLink] = useState<string>();

  const { isLoading } = useQuery(
    [ProjectDetailQuery, projectId],
    () => getProjectById(projectId),
    {
      onSuccess: (res: AxiosResponse) => {
        setProjectData(res.data);
        const groupDocTypes = _.groupBy(res?.data?.documentDetail, "type");

        setDocuments(groupDocTypes);
      },
      onError: (err: AxiosError) => {
        setProjectData([]);
      },
      refetchOnMount: true,
    }
  );

  const userId = projectData?.organizationAffiliationDetail?.userId;
  const projectType = projectData?.projectType;

  const { data: userData, isLoading: userDataLoading } = useQuery(
    ["userDetails", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!userId,
    }
  );

  const { data } = useQuery(["ioDetails", userId], () => getIOdDetails(), {
    onSuccess: (res: AxiosResponse) => {},
    onError: (req: AxiosError) => {},
    enabled: !!userId,
  });

  const [instance, updateInstance] = usePDF({});

  useEffect(() => {
    if (!!userData?.data && !!projectData && !!documents) {
      updateInstance(
        <TreePdf
          projectData={projectData}
          userData={userData?.data}
          documents={documents}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.data, projectData, documents]);

  useEffect(() => {
    const blob = instance.blob;

    if (!!blob) {
      // const dataURL = URL.createObjectURL(blob);
      // setPdfLink(dataURL);
    }
  }, [instance]);

  const treeCanopyDensityValue = Number(
    projectData?.landSpecification?.treeCanopyDensity
  );

  const landArea = Number(projectData?.landArea);

  const occupiedArea = Number(projectData?.landSpecification?.occupiedArea);

  const { control, watch, getValues } = useForm<any>({
    mode: "onTouched",
    defaultValues: {
      landFreeFromEncumbrances: true,
      landSuitableForPlantation: true,
    },
  });

  const exportQuery = (data) => {
    const extractedData = data.map((item) => ({
      Name: item.user.firstName,
      Query: item.query,
      created_at: dateAndTimeStamp(item.created_at, "IST"),
    }));
    downloadSheet(extractedData);
  };
  const ioInfo = data?.data?.implementingOfficerDetails?.map((item) => {
    return {
      value: item.id,
      label: item.division,
    };
  });

  const { mutate: mutateAddIO } = useMutation(
    "AddIO",
    ({
      projectId,
      implementingOfficerId,
    }: {
      projectId: string;
      implementingOfficerId: string;
    }) => addProjectToIo(projectId, implementingOfficerId),
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  return (
    <Box>
      {!isLoading && projectData.length !== 0 ? (
        <>
          <Box>
            <Box {...styles("heading")}>
              Allocate Implementing Officer to Project
            </Box>
            <Box>
              <Box>
                <AutoComplete
                  options={ioInfo}
                  customStyles={{
                    label: defaultStyles.label,
                  }}
                  name={`implementingOfficer`}
                  control={control}
                  placeholder="List of Division"
                  label="List of Division"
                />
                <Button
                  text="Allocate ImplementingOfficer"
                  disabled={!watch("implementingOfficer")}
                  onClick={() => {
                    const data = getValues("implementingOfficer");
                    mutateAddIO({
                      projectId: projectId,
                      implementingOfficerId: data,
                    });
                  }}
                  customStyles={{
                    root: {
                      fontSize: "16px",
                      marginTop: "20px",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box {...styles("projectName")}>
            Activity Registration No: {projectData?.registrationNo}
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>
              {userData?.data?.userType === "ORGANIZATION"
                ? "Entity Details"
                : "Implementing Officer Details"}
            </Box>

            <ViewForm label="Username" value={userData?.data?.firstName} />
            <ViewForm label="Email" value={userData?.data?.email} />
            <ViewForm
              label="Phone Number"
              value={`${userData?.data?.countryCode ?? "+91"} ${
                userData?.data?.phoneNumber
              }`}
            />
            <ViewForm
              label="State"
              value={userData?.data?.currentAddress?.state}
            />
            <ViewForm label="Division" value={userData?.data?.division} />
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>
              Eco-Restoration Block Location Details
            </Box>
            <ViewForm label="State" value={projectData?.address?.stateName} />
            <ViewForm
              label="District"
              value={projectData?.address?.districtName}
            />
            <ViewForm
              label="Allocated Division"
              value={projectData?.implementingOfficer?.division}
            />
            <ViewForm
              label="Range"
              value={projectData?.landSpecification?.range}
            />
            <ViewForm
              label="Beat"
              value={projectData?.landSpecification?.beat}
            />
            <ViewForm
              label="Survey No"
              value={projectData?.address?.khasraNo}
            />
            <ViewForm
              label="Compartment Number"
              value={projectData?.landSpecification?.compartmentNumber}
            />
            <ViewForm
              label="TreeCanopy Density"
              value={treeCanopyDensityValue.toFixed(2)}
            />

            <Box {...styles("imageWrapper")}>
              {projectData?.images?.map((item, idx) => {
                return (
                  <Card
                    key={item.imageUrl.fileUrl}
                    title={`GeoTag Image`}
                    subheader={dateAndTimeStamp(item?.updated_at, "IST")}
                    image={item.imageUrl.fileUrl}
                  />
                );
              })}
            </Box>
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Eco-Restoration Block Details.</Box>

            <ViewForm
              label="Total Land Area(Hectares)"
              value={`${landArea.toFixed(2)} ${projectData?.areaUnit}`}
            />
            <ViewForm
              label="Occupied Area"
              value={`${occupiedArea.toFixed(2)} ${projectData?.areaUnit}`}
            />
            <ViewForm label="Land Type" value={projectData?.landType} />
            <ViewForm
              label="EdaphicDetails"
              value={projectData?.landSpecification?.edaphicDetails}
            />

            <ViewForm
              label="Slope And Terrain"
              value={projectData?.landSpecification?.slopeAndTerrain}
            />
            <ViewForm
              label="Geological Features"
              value={projectData?.landSpecification?.geologicalFeatures}
            />

            <ViewForm
              label="Hydrological Conditions"
              value={projectData?.landSpecification?.hydrologicalConditions}
            />

            <ViewForm
              label="Forest Type"
              value={projectData?.landSpecification?.forestType}
            />

            <Box>
              {projectData?.treeSpecificationDetail?.map((data) => {
                return (
                  <>
                    {data?.treeType === 0 ? (
                      <Box {...styles("group")}>
                        <ViewForm
                          label="Existing Tree Species"
                          value={data?.speciesName}
                        />

                        {!!data?.treeSpecies &&
                          Object?.keys(data?.treeSpecies)?.map((tree) => (
                            <ViewForm
                              key={data?.treeSpecies?.tree}
                              label="Name"
                              value={data?.treeSpecies?.tree}
                            />
                          ))}
                        <ViewForm
                          label="Existing Tree count"
                          value={data?.treeCount}
                        />
                      </Box>
                    ) : (
                      <Box {...styles("group")}>
                        <ViewForm
                          label="Tree Species Proposed"
                          value={data?.speciesName}
                        />

                        {!!data?.treeSpecies && (
                          <>
                            <ViewForm
                              label="Name"
                              value={data?.treeSpecies?.name}
                            />
                            <ViewForm
                              label="Species Name"
                              value={data?.treeSpecies?.speciesName}
                            />
                            <ViewForm
                              label="Species Type"
                              value={data?.treeSpecies?.speciesType}
                            />
                          </>
                        )}
                        <ViewForm
                          label="Number Of Trees Proposed"
                          value={data?.treeCount}
                        />
                      </Box>
                    )}
                  </>
                );
              })}
            </Box>
            {projectType === ProjectType.TREE_PLANTATION && (
              <>
                <Box {...styles("subHeading")}>Tree Specifications:</Box>

                <ViewForm
                  label="Start Date of Plantation (DD/MM/YYYY)"
                  value={getFormattedDate(projectData?.projectStartDate)}
                />
                <ViewForm
                  label="Activity Duration (in years)"
                  value={projectData?.projectDuration}
                />
              </>
            )}
          </Box>
          {projectData?.remarks && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Remarks</Box>
              <ViewForm label="Remarks" value={projectData?.remarks} />
            </Box>
          )}
          {projectData?.landSpecification?.description && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Additional Information</Box>
              <ViewForm
                label="Additional Information"
                value={projectData?.landSpecification?.description}
              />
            </Box>
          )}
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Documents</Box>
            {Object.keys(documents)?.map((doc) => (
              <>
                <Box>{PROJECT_DOCUMENT_TYPE_MAP[doc]}</Box>
                <Box {...styles("documentGroup")}>
                  {documents[doc]?.map((item) => (
                    <ViewImages
                      key={item.documentUrl.fileUrl}
                      fileUrl={item.documentUrl.fileUrl}
                      label={"KML File"}
                    />
                  ))}
                </Box>
              </>
            ))}
          </Box>
          <Box {...styles("formWrapper")}>
            {!userDataLoading && !isLoading && (
              <MapComponent
                kmlUrl={projectData?.documentDetail[0].documentUrl.fileUrl}
              />
            )}
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Forest Department Undertaking</Box>
            <Checkbox
              name="landFreeFromEncumbrances"
              control={control}
              label={"The land is free from all types of encumbrances"}
              rules={{
                required: true,
              }}
              disabled
            />
            <Checkbox
              name="landSuitableForPlantation"
              control={control}
              label={"The land is suitable for Eco-Restoration"}
              rules={{
                required: true,
              }}
              disabled
            />
          </Box>
          {projectData?.snoRemarks && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>SNO Remarks</Box>
              <ViewForm label="SNO Remarks" value={projectData?.snoRemarks} />
            </Box>
          )}

          {projectData?.projectQuery.length > 0 && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("infoContainer")}>
                <Button
                  onClick={() => exportQuery(projectData?.projectQuery)}
                  text={""}
                  variant="outlined"
                  src="/assets/svg/export.svg"
                  customStyles={{
                    root: {
                      minWidth: "40px",
                    },
                  }}
                />
                <Box {...styles("heading")}>Project Query</Box>
              </Box>
              {projectData?.projectQuery.map((item) => (
                <Box sx={{ paddingTop: "20px" }}>
                  <ViewForm
                    label={item.user.firstName}
                    value={item.query}
                    date={dateAndTimeStamp(item.created_at, "IST")}
                  />
                  <Divider />
                </Box>
              ))}
            </Box>
          )}
        </>
      ) : (
        <Box {...styles("loading")}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default TreeProject;
