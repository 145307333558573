import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Dialog,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";

import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useQuery } from "react-query";
import { getImages } from "src/utils/api/dashboard";

import { useState } from "react";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
};

export function ProgressHistory({ data, topHeader }: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);

  const { data: photosData, isLoading } = useQuery(
    ["fetchPhotos", selectedRow],
    () => getImages(selectedRow), // API call to fetch photos for the selected row
    {
      enabled: !!selectedRow, // Only run when a row is selected
    }
  );

  const handleOpenDialog = (row: any) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  return (
    <TableContainer component={Paper} sx={{ height: "fit-content" }}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((seedData, index) => {
            console.log(seedData, "======+++");
            if (!seedData) return null;
            return (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.progressDate
                    ? new Date(
                        seedData.progressDate.split("T")[0]
                      ).toLocaleDateString("en-GB")
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.fromDate
                    ? new Date(
                        seedData.fromDate.split("T")[0]
                      ).toLocaleDateString("en-GB")
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.toDate
                    ? new Date(
                        seedData.toDate.split("T")[0]
                      ).toLocaleDateString("en-GB")
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.quantityInNumber}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {seedData.cost}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "70px" })}
                >
                  <IconButton
                    aria-label="view photos"
                    onClick={() => handleOpenDialog(seedData.id)}
                  >
                    <ImageSearchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>GeoTagged Photos</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <CircularProgress />
          ) : photosData && photosData.data.data.length > 0 ? (
            <div>
              {photosData.data.data.map((photo: any, idx: number) => (
                <img
                  key={idx}
                  src={photo.url}
                  alt={photo.key}
                  style={{ width: "100%", marginBottom: "20px" }}
                />
              ))}
            </div>
          ) : (
            <p>No GeoTagged Photos Available</p>
          )}
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
}
