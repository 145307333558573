export type PathType<T extends string = string> = Record<T, string>;
const createPath = <T extends string>(data: PathType<T>) => data;

export const path = createPath({
  home: "/",
  userProfile: "/admin",
  users: "/users",
  notFound: "/not-found",
  signIn: "/login",
  projectDetailView: "/project/details",
  login: "/login",
  forgetPassword: "/forget-password",
  myProfile: "/my-profile",
  demandDraft: "/project/demand-draft",
  viewDemand: "/view-demand-draft",
  registeredLands: "/registered-lands",
  userDetails: "/user-details",
  landOwnershipDetails: "/landOwnership/details",
  implementingAgency: "/implementing-agency",
  marketWatch: "/market-watch",
  changePassword: "/change-password",
  fundTransfer: "/fund-transfer",
  fundApplicationDetails: "/fund-application-details",
  costEstimateDetails: "/cost-estimates-detials",
  progressDetails: "/progress-details",
  advanceWorkProgress: "/progress-details-history",
  progressDetailsReport: "/progress-details-report",
  divisionProgressDetailsReport: "/division-progress-reports",
  monitoring: "/monitoring",
  monitoringDetails: "/monitoring-details",
  submittedMonitorings: "/submitted-monitorings",
});
