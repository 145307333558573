import { Box } from "@mui/material";
import ViewForm from "src/components/common/ViewForm";
import { getStyles } from "src/styles/theme";
import defautlStyles from "./styles";
import ViewImages from "src/components/common/ViewImage";

export function FencingView({ data, images }: any) {
  const fencingDetail = data;
  const styles = getStyles(defautlStyles);

  const viewData = {
    "Actual RMT Found (m)": fencingDetail.actualRMTFound,
    "Actual Pillar (No.)": fencingDetail.actualPillars,
    "Month and Year": fencingDetail.yearMonth,
    Observations: fencingDetail.remark,
  };
  return (
    <Box {...styles("viewWrapper")}>
      {Object.entries(viewData).map(([label, value]) => (
        <ViewForm label={label} value={value} key={label} />
      ))}
      <Box {...styles("heading")}>Geo Tagged Images</Box>
      <Box {...styles("imageContainer")}>
        {images?.map((image) => (
          <ViewImages fileUrl={image.url} />
        ))}
      </Box>
    </Box>
  );
}
