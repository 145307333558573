import { Box, Grid } from "@mui/material";
import {
  Button,
  DateInput,
  FileUpload,
  Input,
} from "../../../common/FormComponents";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import { useForm } from "react-hook-form";

import { AxiosError, AxiosResponse } from "axios";
import { getProgressDetailsHistory } from "src/utils/api/dashboard";

import { useAppSelector } from "src/redux/store";
import { ProgressHistory } from "src/components/common/DashBoardTable/progressHistory";
import { useQuery } from "react-query";
import ViewForm from "src/components/common/ViewForm";

export function AddProgress({ rowData, selfState, refetch }: any) {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const { data: progressDetails } = useQuery(
    ["userDetails", rowData.id],
    () => getProgressDetailsHistory(rowData.id),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      refetchOnMount: "always", // Refetches every time the component mounts
      refetchOnWindowFocus: true,
    }
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      cost: 0,
      quantity: 0,
    },
  });

  const commonTableHeaders = [
    "Updated Date",
    "From Datee",
    "To Date",
    `Physical Progress (${progressDetails?.data?.data?.physicalProgressUnit})`,
    "Financial Progress(₹)",
    "View GeoTagged Photos",
  ];

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("heading")}>Progress History</Box>
      <Box sx={{ margin: "20px", width: "fit-content" }}>
        <ViewForm
          label={"Main Component"}
          value={progressDetails?.data?.data?.target?.indicator?.name}
        />
        <ViewForm
          label={"Sub Component"}
          value={progressDetails?.data?.data?.target?.activity?.name}
        />
        <ViewForm
          label={"Particular Item of Work"}
          value={progressDetails?.data?.data?.target?.perticularOfItem}
        />
      </Box>

      <ProgressHistory
        topHeader={commonTableHeaders}
        data={progressDetails?.data?.data?.progress?.rows || []}
      />
    </Box>
  );
}
