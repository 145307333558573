import { Box, Button, Collapse, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import MultiSelect from "src/components/common/MultiSelect";
import ProgressCard from "src/components/common/ProgressCard";
import Fallback from "src/components/common/Fallback";
import { getAllUser, getProgressReportDetails } from "src/utils/api/dashboard";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { getStates } from "src/utils/api/roles";
import { DashBoardTable } from "src/components/common/DashBoardTable";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ProgressDetailReport } from "src/components/common/DashBoardTable/progressDetailReport";
import { AxiosError, AxiosResponse } from "axios";

const ProgressReport = () => {
  const [states, setStates] = useState<any>([]);
  const [filtersState, setFiltersState] = useState([]);

  const [entityfilterKey, entitySetFilterKey] = useState({
    enetityStateIds: null,
  });

  const [usertQueryParams, setUserQueryParams] = useState<{
    role: string;
    status: string;
  }>({
    role: "Entity/ Green Credit Applicant",
    status: "ACTIVE",
  });

  const [tableRows, setTableRows] = useState([]);

  const [tableState, setTableState] = useState(false);

  const [queryParams, setQueryParams] = useState<{
    progressReportFormat: string;
    indicatorIds?: string;
    entityIds?: string;
  }>({
    progressReportFormat: "TABLE",
    entityIds: undefined,
  });

  const [boxQueryParams, setBoxQueryParams] = useState<{
    projectStateIds?: string[];
    progressReportFormat?: string;
  }>({
    projectStateIds: undefined,
    progressReportFormat: "BOX",
  });

  const { data: dashboard, isLoading: dashboardLoading } = useQuery(
    ["ProgressReportDetails", boxQueryParams],
    () => getProgressReportDetails(boxQueryParams),
    {
      refetchOnMount: true,
    }
  );

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const { data: dashboardTable, isLoading: dashboardLoadingTable } = useQuery(
    ["ProgressReportDetailstable", queryParams],
    () => getProgressReportDetails(queryParams),
    {
      onSuccess: (res) => {
        if (res) {
          setTableRows(
            res?.data?.data?.progress.map((items, idx) => ({
              id: idx,
              sno: idx + 1,
              state: items.state,
              indicatorName: items.indicatorName,
              targetInUnits: items.targetInUnits,
              costTarget: items.costTarget,
              progressInUnits: items.progressInUnits,
              costProgress: items.costProgress,
              redirectionKeyValue: items.redirectionKeyValue,
              indicatorId: items.indicatorId,
            }))
          );
        }
      },
      refetchOnMount: true,
    }
  );

  const handleCardClick = (indicatorId: string) => {
    setQueryParams((prevFilter) => {
      const newState = {
        ...prevFilter,
        indicatorIds: indicatorId,
      };
      return newState;
    });
  };

  const dashboardTableData: DashBoardTable = {
    topHeader: ["", "Target", "Progress"],
    header: [
      "S.No",
      "State",
      "Activity Name",
      "Physical",
      "Finanical",
      "Physical",
      "Finanical",
    ],
    data: tableRows,
  };

  const styles = getStyles(defaultStyles);

  const { data: userData } = useQuery(
    ["users", usertQueryParams],
    () => getAllUser(usertQueryParams),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const entityInfo = userData?.data?.rows?.map((item) => {
    return {
      id: item.id,
      name: item.firstName,
    };
  });

  useEffect(() => {
    setFiltersState(dashboard?.data?.state);
  }, [dashboard]);

  return (
    <>
      <Box {...styles("topHeader")}>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <MultiSelect
            options={states}
            onChange={(e: any) => {
              const projectStateIds = e.length ? e : null;
              setQueryParams((prev) => ({
                ...prev,
                projectStateIds,
              }));
              setBoxQueryParams((prev) => ({
                ...prev,
                projectStateIds,
              }));
            }}
          />
          <MultiSelect
            options={entityInfo}
            label={"Filter By Entity"}
            onChange={(e: any) => {
              entitySetFilterKey((prevFilter) => {
                const newState = {
                  ...prevFilter,
                  entityIds: e.length ? e : null,
                };

                setQueryParams({
                  ...queryParams,
                  entityIds: newState?.entityIds,
                });

                return newState;
              });
            }}
          />
        </Box>
        <Box {...styles("statsCardWrapper", { marginTop: "20px" })}>
          {dashboard?.data?.data?.progress?.map((stat, index) => (
            <ProgressCard
              key={index}
              title={`${stat?.indicatorName?.toUpperCase()}`}
              value={`${stat?.costProgress}`}
              area={stat?.progressInUnits}
              customStyles={{ valueCard: styles("statsCard").sx }}
              id={stat?.indicatorId}
              onClick={handleCardClick}
            />
          ))}
        </Box>
      </Box>
      {dashboardLoadingTable ? (
        <Fallback />
      ) : (
        <Box {...styles("dashboardTableWrapper")}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" {...styles("heading")}>
              Status of Progress Detials
            </Typography>
            <Button
              variant="outlined"
              onClick={() => setTableState(!tableState)}
            >
              {tableState ? (
                <>
                  Compress
                  <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
                </>
              ) : (
                <>
                  Expand
                  <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
                </>
              )}
            </Button>
          </Box>
          <Collapse in={tableState}>
            <ProgressDetailReport {...dashboardTableData} />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default ProgressReport;
