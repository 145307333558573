import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { getStyles } from "src/styles/theme";
import ViewForm from "src/components/common/ViewForm";
import defaultStyles from "./styles";
import ViewImages from "src/components/common/ViewImage";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
};
const topHeader = [
  "S. No.",
  "Botanical Name",
  "Common Name",
  "No. of Seedling",
  "Average Height (m)",
  "Survival (%)",
  "Observations",
];
export function PlantationView({ data, images }: any) {
  const styles = getStyles(defaultStyles);

  const tableData = data?.species.map((item) => ({
    botanicalName: item.species?.botanicalName,
    commonName: item.species?.commonName,
    numberOfSeedlings: item.species?.numberOfSeedlings,
    avgHeight: item.avgHeight,
    avgSurvival: item.avgSurvival,
    remark: item.remark,
  }));
  const tableLabel = {
    "Average Height (m)": data?.plantationMonitoring?.avgHeight,
    "Average Survival (%)": data?.plantationMonitoring?.avgSurvival,
  };

  return (
    <Box {...styles("viewWrapper")}>
      <Box>
        {Object.entries(tableLabel).map((item) => (
          <ViewForm label={item[0]} value={item[1]} />
        ))}
      </Box>
      <TableContainer
        component={Paper}
        sx={{ height: "fit-content", mt: "20px" }}
      >
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead {...styles("header")}>
            <TableRow sx={{ backgroundColor: "custom.greyish.3" }}>
              {topHeader.map((item, index) => (
                <TableCell align="center" {...styles("cell")} key={index}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                if (!item) return null;
                return (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "30px" })}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      {item.botanicalName}
                    </TableCell>
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      {item.commonName}
                    </TableCell>
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      {item.numberOfSeedlings}
                    </TableCell>
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      {item.avgHeight}
                    </TableCell>
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      {item.avgSurvival}
                    </TableCell>
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      {item.remark}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Box sx={{ mt: "20px", fontSize: "17px", fontWeight: "600" }}>
          Overall Observations on Sapling Survival:
        </Box>
        <Box sx={{ fontSize: "17px", fontWeight: "400" }}>
          {data?.plantationMonitoring.remark || ""}
        </Box>
      </Box>
      <Box {...styles("imageContainer")}>
        {images?.map((item) => (
          <ViewImages fileUrl={item.url} />
        ))}
      </Box>
    </Box>
  );
}
