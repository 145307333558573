import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
};

export function MonitoringMemberDetailsTable({
  data,
  topHeader,
}: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  return (
    <TableContainer
      component={Paper}
      sx={{ height: "fit-content", mt: "20px" }}
    >
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              if (!item) return null;
              return (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    {...styles("cell", { width: "30px" })}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    {...styles("cell", { width: "100px" })}
                  >
                    {item.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    {...styles("cell", { width: "100px" })}
                  >
                    {item.designation}
                  </TableCell>
                  <TableCell
                    align="center"
                    {...styles("cell", { width: "100px" })}
                  >
                    {item.phoneNumber}
                  </TableCell>
                  <TableCell
                    align="center"
                    {...styles("cell", { width: "100px" })}
                  >
                    {item.email}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
