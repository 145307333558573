import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ViewForm from "src/components/common/ViewForm";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import ViewImages from "src/components/common/ViewImage";

const topHeader = [
  "S. No.",
  "Botanical Name",
  "Common Name",
  "Casualty Replaced",
  "Observations",
];
export function ProtectionView({ data, images }: any) {
  const styles = getStyles(defaultStyles);

  const monitoring = {
    "Van Kutir Type": data.protectionMonitoring.vanKutirType,
    "Van Kutir Status": data.protectionMonitoring.vanKutirStatus,
    "Watchman Ward Persons": data.protectionMonitoring.watchmanWardPersons,
    "Fire Line Created": data.protectionMonitoring.isFireLineErected
      ? "Yes"
      : "No",
    "Maintenance of FireLine": data.protectionMonitoring.maintenanceOfFireLine,
    Weeding: data.protectionMonitoring.weeding,
    Observations: data.protectionMonitoring.remark,
  };

  const tableData = data?.species?.map((item) => ({
    botanicalName: item.species?.botanicalName,
    commonName: item.species?.commonName,
    casualtyReplced: item.casualtyReplaced,
    remark: item.remark,
  }));

  return (
    <Box {...styles("viewWrapper")}>
      <Box>
        {Object.entries(monitoring).map((item) => (
          <ViewForm label={item[0]} value={item[1]} />
        ))}
      </Box>

      <Box>
        <TableContainer
          component={Paper}
          sx={{ height: "fit-content", mt: "20px" }}
        >
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead {...styles("header")}>
              <TableRow sx={{ backgroundColor: "custom.greyish.3" }}>
                {topHeader.map((item, index) => (
                  <TableCell align="center" {...styles("cell")} key={index}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                tableData.length > 0 &&
                tableData.map((item, index) => {
                  if (!item) return null;
                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "30px" })}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.botanicalName}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.commonName}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.casualtyReplced}
                      </TableCell>
                      <TableCell
                        align="center"
                        {...styles("cell", { width: "100px" })}
                      >
                        {item.remark}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box {...styles("heading")}>Geo Tagged Images</Box>
      <Box {...styles("imageContainer")}>
        {images && images?.map((image) => <ViewImages fileUrl={image.url} />)}
      </Box>
    </Box>
  );
}
