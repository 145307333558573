import { Box } from "@mui/material";
import ViewForm from "src/components/common/ViewForm";
import { getStyles } from "src/styles/theme";
import defautlStyles from "./styles";

import ViewImages from "src/components/common/ViewImage";

export function SmcView({ data, images, submitted }: any) {
  const styles = getStyles(defautlStyles);
  const smcDetail = data;
  const viewData = {
    "Contour Trench (No.)": smcDetail.contourTrench,
    "Water Harvesting Structure Type": smcDetail.waterHarvestingStructureType,
    "Water Harvesting Structures (No.)": smcDetail.waterHarvestingStructures,
    "Other Details": smcDetail.otherDetails,
    "Other Details Count (No.)": smcDetail.otherDetailsCounts,
    Observations: smcDetail.remark,
  };

  return (
    <Box>
      <Box {...styles("viewWrapper")}>
        {Object.entries(viewData).map((item) => (
          <ViewForm label={item[0]} value={item[1]} />
        ))}

        <Box {...styles("heading")}>Geo Tagged Images</Box>
        <Box {...styles("imageContainer")}>
          {images?.map((item) => (
            <ViewImages fileUrl={item.url} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
